<template>
    <v-container>
        <SpinnerComponent v-if="verificacionRefinanciamiento.isLoading" spinning text="Cargando información..." />
        <template v-else>
            <RetryDataLoading :loadable="verificacionRefinanciamiento" :retry-handler="init">
                <v-card v-if="verificacionRefinanciamiento.data.puede_manipular_refinanciamiento">
                    <v-card-title class="flex-sm-nowrap align-start" style="gap: 8px;">
                        <h3 class="text-h6 font-weight-bold text-uppercase" style="color: var(--v-blueGrayMinsal-darken3); line-height: 36px; word-break: break-word;">
                            <NavButton style="transform: translateY(-1px);" />
                            Refinanciar proceso de compra
                        </h3>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                        <v-autocomplete
                            v-model="obsSeleccionado"
                            :loading="obs.isLoading"
                            :disabled="cifrados.isLoading"
                            label="OBS"
                            :items="listaObs"
                            outlined
                            item-value="id"
                            item-text="nombre"
                            hide-details
                            clearable
                            @change="abrirConfirmacionCambioObs"
                        />
        
                        <div class="d-flex align-center flex-wrap mt-8" style="gap: 16px;">
                            <v-btn 
                                :disabled="!obsSeleccionado"
                                :loading="cifrados.isLoading"
                                :color="financiamientos.length > 0 ? 'secondary' : 'primary'"
                                @click.stop="modalAgregarFinanciamientoAbierta = true"
                                class="btn-principal"
                            >
                                <v-icon color="white">mdi-plus</v-icon>
                                Agregar fuente de financiamiento
                            </v-btn>
                            <v-btn 
                                v-if="financiamientos.length > 0" 
                                color="primary"
                                class="btn-principal"
                                @click.stop="confirmacionCreacionRefinanciamiento = true"
                            >
                                <v-icon color="white" class="mr-1">mdi-content-save</v-icon>
                                Guardar refinanciamiento
                            </v-btn>
                        </div>
        
                        <DataTableComponent 
                            dense
                            no-gutters
                            :tiene_paginacion="false"
                            :items="financiamientos"
                            :headers="headers"
                            class="mt-4"
                        >
                            <template v-slot:item.cifrado_presupuestario="{ item }">
                                {{ item.financiamiento.tipo_fondo }}
                                -
                                {{ item.financiamiento.cifrado }}
                            </template>
                            <template v-slot:item.monto="{ item }">
                                <b>{{ toMoneyFormat(item.monto) }}</b>
                            </template>
                            <template v-slot:item.accion="{ item }">
                                <div class="d-flex justify-center align-center" style="gap: 8px;">
                                    <v-btn icon @click.stop="confirmarEliminacionFinanciamiento(item)">
                                        <v-icon color="secondary">mdi-trash-can</v-icon>
                                    </v-btn>
                                </div>
                            </template>
                        </DataTableComponent>
                    </v-card-text>
                </v-card>
                <template v-else>
                    <v-alert type="info" class="mt-4" dense>
                        No puede crear refinanciamientos para este proceso ya que se encuentra fuera de su unidad.
                    </v-alert>
                    <v-btn color="primary" large @click.stop="volver">Volver</v-btn>
                </template>
            </RetryDataLoading>
        </template>

        <ModalAgregarFinanciamiento 
            :is-open.sync="modalAgregarFinanciamientoAbierta"
            :obs="obsSeleccionado"
            :cifrados="cifrados"
            :financiamientos_agregados="financiamientosAgregados"
            @on-save="manejarGuardadoFinanciamiento"
        />

        <ConfirmationModalComponent 
            :is-open="confirmacionCambioObs"
            title="¿Desea cambiar de OBS?"
            description="Al cambiar de OBS se eliminarán los financiamientos agregados"
            @confirm="confirmarCambioObs"
            @cancel="cancelarCambioObs"
        />

        <ConfirmationModalComponent 
            :is-open="confirmacionEliminadoFinanciamiento"
            description="¿Desea eliminar el financiamiento?"
            @confirm="eliminarRefinanciamiento"
            @cancel="confirmacionEliminadoFinanciamiento = false"
        />

        <ConfirmationModalComponent 
            :is-open="confirmacionCreacionRefinanciamiento"
            :is-loading="guardadoRefinanciamiento.isLoading"
            description="¿Desea continuar con la creación del financiamiento de compra?"
            @confirm="crearRefinanciamiento"
            @cancel="confirmacionCreacionRefinanciamiento = false"
        />
    </v-container>
</template>
<script>
import { createLoadable, setLoadableResponse, toggleLoadable, isResponseSuccesful } from '@/utils/loadable';
import ModalAgregarFinanciamiento from './components/ModalAgregarFinanciamiento.vue';
import { ConfirmationModalComponent, NavButton } from '@/components/utils';
import SpinnerComponent from '@/components/utils/SpinnerComponent.vue';
import RetryDataLoading from '@/components/utils/RetryDataLoading.vue';
import DataTableComponent from '@/components/DataTableComponent.vue';
import { toMoneyFormat } from '@/utils/data';
import { capitalize } from 'lodash';

export default {
    name: 'RefinanciarProcesoCompra',
    components: { NavButton, DataTableComponent, ModalAgregarFinanciamiento, ConfirmationModalComponent, SpinnerComponent, RetryDataLoading },
    data: () => ({
        obsSeleccionado: null,
        obsPrevio: null,
        financiamientos: [],
        // UI
        idProcesoCompra: null,
        financiamientoActivo: null,
        modalAgregarFinanciamientoAbierta: false,
        confirmacionCambioObs: false,
        confirmacionEliminadoFinanciamiento: false,
        confirmacionCreacionRefinanciamiento: false,
        // Data
        cifrados: createLoadable([]),
        obs: createLoadable([]),
        guardadoRefinanciamiento: createLoadable(null),
        verificacionRefinanciamiento: createLoadable(null),
    }),
    computed: {
        listaObs() {
            return this.obs.data.map((item) => ({
                id: item.id,
                nombre: `${item.codigo} - ${capitalize(item.nombre)}`,
            }));
        },
        headers() {
            return [
                { text: 'Código presupuestario', value: 'cifrado_presupuestario', align: 'center', sortable: false },
                { text: 'Monto ($)', value: 'monto', align: 'center', sortable: false },
                { text: 'Acción', value: 'accion', align: 'center', sortable: false },
            ];
        },
        financiamientosAgregados() {
            return this.financiamientos.map((financiamiento) => financiamiento.financiamiento.id);
        },
    },
    methods: {
        // UI
        toMoneyFormat,
        abrirConfirmacionCambioObs() {
            if (this.financiamientos.length > 0) {
                this.confirmacionCambioObs = true;
                return
            }

            this.cargarCifrados();
        },
        volver() {
            if (window.history.length > 1) {
                this.$router.back();
            } else {
                this.$router.push('/');
            }
        },
        // Cambio OBS
        confirmarCambioObs() {
            this.confirmacionCambioObs = false;
            this.financiamientos = [];
            this.cargarCifrados();
        },
        cancelarCambioObs() {
            this.confirmacionCambioObs = false;
            this.obsSeleccionado = this.obsPrevio;
        },
        manejarGuardadoFinanciamiento(financiamiento) {
            this.financiamientos.push(financiamiento);
        },
        // Eliminacion OBS
        confirmarEliminacionFinanciamiento(financiamiento) {
            this.financiamientoActivo = financiamiento;
            this.confirmacionEliminadoFinanciamiento = true;
        },
        eliminarRefinanciamiento() {
            this.confirmacionEliminadoFinanciamiento = false;
            this.financiamientos = this.financiamientos.filter((financiamiento) => financiamiento.financiamiento.id !== this.financiamientoActivo.financiamiento.id);
        },
        // Data
        async cargarCatalogoObs() {
            toggleLoadable(this.obs);
            const { data } = await this.services.RefinanciamientoCompras.cargarObsSegunNecesidades(this.idProcesoCompra, { pagination: false });
            setLoadableResponse(this.obs, data);
        },
        async cargarCifrados() {
            if (!this.obsSeleccionado) {
                this.cifrados = createLoadable([]);
                return;
            }

            toggleLoadable(this.cifrados);
            const { data } = await this.services.RefinanciamientoCompras.cargarCifradosSegunObs(this.idProcesoCompra, this.obsSeleccionado, { pagination: false });
            setLoadableResponse(this.cifrados, data);
        },
        async crearRefinanciamiento() {
            const fondos = this.financiamientos.map((financiamiento) => ({ 
                id_financiamiento_unidad: financiamiento.financiamiento.id, 
                monto: financiamiento.monto  
            }));

            const payload = {
                id_obs: this.obsSeleccionado,
                fondos: fondos,
            };

            toggleLoadable(this.guardadoRefinanciamiento);
            const { data } = await this.services.RefinanciamientoCompras.crearRefinanciamiento(this.idProcesoCompra, payload);
            this.confirmacionCreacionRefinanciamiento = false;
            setLoadableResponse(this.guardadoRefinanciamiento, data, { showAlertOnSuccess: true });

            if (isResponseSuccesful(data)) {
                this.$router.push(`/refinanciamiento-compras/vinculaciones/${this.idProcesoCompra}`);
            }
        },
        async verificarManipulacionRefinanciamiento() {
            toggleLoadable(this.verificacionRefinanciamiento);
            const { data } = await this.services.RefinanciamientoCompras.verificarPermisosRefinanciamiento(this.idProcesoCompra);
            setLoadableResponse(this.verificacionRefinanciamiento, data);
        },
        async init() {
            this.idProcesoCompra = this.$route.params.id_proceso_compra;
            await this.verificarManipulacionRefinanciamiento();
            this.cargarCatalogoObs();
        },
    },
    watch: {
        obsSeleccionado: {
            handler(_, valorPrevio) {
                this.obsPrevio = valorPrevio;
            },
        },
    },
    created() {
        this.init();
    },
}
</script>
<style scoped>
    :deep(.btn-principal) {
        height: auto !important;
        padding: 8px 16px !important;
        max-width: 100% !important;
    }

    :deep(.v-btn__content) {
        max-width: 100%;
        word-break: break-word;
        overflow: hidden;
        white-space: normal;
    }
</style>